import { DriverID } from "./model";

export function pad(num: number, size: number) {
  let num_str = num.toString();
  while (num_str.length < size) num_str = "0" + num_str;
  return num_str;
}

function idxToStr(idx: any): string | null {
  if (Number.isInteger(idx)) {
    return idx.toString();
  }
  return null;
}

function idxToNumber(idx: any): number | null {
  if (typeof idx == "number") {
    return idx;
  }
  return null;
}

export function printDriverID(id: DriverID): string {
  let asAny = id as any;

  let asBinance = idxToStr(asAny["Binance"]);
  if (asBinance != null) {
    return `Binance_${asBinance.padStart(2, "0")}`;
  }

  let asDydx = idxToStr(asAny["Dydx"]);
  if (asDydx != null) {
    return `Dydx_${asDydx.padStart(2, "0")}`;
  }

  throw Error(`unsupported id: ${id}`);
}

export function getIdxFromDriverID(id: DriverID): number {
  let asAny = id as any;

  let asBinance = idxToNumber(asAny["Binance"]);
  if (asBinance != null) {
    return asBinance;
  }

  let asDydx = idxToNumber(asAny["Dydx"]);
  if (asDydx != null) {
    return asDydx;
  }

  throw Error(`unsupported id: ${id}`);
}
