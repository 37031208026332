import { BinancePriceUpdate, ShowSnackMsg, WebSocketEvent } from "./model";
import { getSummary, refreshShards } from "./query";
import { Dispatcher } from "./reducer";
import { printDriverID } from "./utils";

export function init_binance_price_socket(
  dispatcher: Dispatcher,
  showMsg: ShowSnackMsg
) {
  let socket = new WebSocket("wss://fstream.binance.com/ws/!miniTicker@arr");
  socket.addEventListener("open", function () {
    showMsg("binance ws connected.");
  });
  socket.addEventListener("message", function (raw) {
    let updates: BinancePriceUpdate[] = JSON.parse(raw.data);
    dispatcher({ type: "PriceUpdate", data: updates });
  });
  socket.addEventListener("close", () => {
    showMsg("binance socket closed.");
    init_binance_price_socket(dispatcher, showMsg);
  });
  socket.addEventListener("error", (e) => {
    showMsg("binance socket error-ed out.");
  });
}

export function init_socket(
  authKey: string,
  dispatcher: Dispatcher,
  showMsg: ShowSnackMsg
) {
  let socket = new WebSocket("wss://ws.txie.studio");
  socket.addEventListener("open", function () {
    let json = JSON.stringify({ event: "AUTH", key: authKey });
    socket.send(json);
    showMsg("connected to btrade socket");
    refreshShards(authKey, dispatcher, showMsg);
    getSummary(authKey, dispatcher);
  });

  // Listen for messages
  socket.addEventListener("message", function (raw) {
    let event: WebSocketEvent = JSON.parse(raw.data);
    dispatcher(event);
    switch (event.type) {
      case "SessionClear":
        showMsg(`[${printDriverID(event.driverId)}][${event.symbol}] profit.`);
        break;
    }
  });

  socket.addEventListener("close", () => {
    init_socket(authKey, dispatcher, showMsg);
    showMsg("btrade socket closed.");
  });

  socket.addEventListener("error", (e) => {
    showMsg("btrade socket errored out.");
  });
}
