import { Box, Container, Paper, Tab, Tabs, Typography } from "@mui/material";
import React, { ReactElement, ReactNode } from "react";

function TabPanel(props: {
  index: number;
  tabValue: number;
  children: ReactNode;
}) {
  const { index, tabValue, children } = props;
  return (
    <Paper hidden={tabValue !== index}>
      {tabValue === index && props.children}
    </Paper>
  );
}

function ControlCenter() {
  const [tabValue, setTabValue] = React.useState(0);
  return (
    <Container maxWidth="lg">
      <Tabs value={tabValue} onChange={(e, v) => setTabValue(v)}>
        <Tab label="Override" />
      </Tabs>
      <TabPanel index={0} tabValue={tabValue}>
        <Typography variant="h2" gutterBottom>
          Asset Transfer
        </Typography>
      </TabPanel>
    </Container>
  );
}

export default ControlCenter;
