import {
  AppState,
  BinancePriceUpdate,
  DriverID,
  SessionClearEvent,
  Sessions,
  SessionStatus,
  SessionUpdateEvent,
  Shards,
  ShardStatus,
  Summary,
  WebSocketEvent,
} from "./model";
import { printDriverID } from "./utils";
import { Map } from "immutable";

export type Dispatcher = React.Dispatch<Action>;

export type Action =
  | WebSocketEvent
  | { type: "LoadShards"; data: Shards }
  | { type: "EmaUpdate"; data: boolean | null }
  | { type: "PriceUpdate"; data: BinancePriceUpdate[] }
  | { type: "SummaryUpdate"; data: Summary };

function updateShard(
  state: AppState,
  driverID: DriverID,
  updater: (old: ShardStatus) => ShardStatus
): AppState {
  const driverIDStr = printDriverID(driverID);
  let shards = state.shards;
  let shard = shards.get(driverIDStr);
  if (!shard) {
    throw Error(`unable to get shard for ${driverIDStr}`);
  }
  let newShard = updater(shard);

  shards = shards.set(driverIDStr, newShard);
  return {
    ...state,
    shards,
  };
}

function updateSessionInShardStatus(
  shard: ShardStatus,
  symbol: string,
  updater: (old: SessionStatus) => SessionStatus | null
): ShardStatus {
  let sessions = { ...shard.sessions };
  let old = sessions[symbol];
  let updated = updater(old);
  if (updated) {
    sessions[symbol] = updated;
  } else {
    delete sessions[symbol];
  }
  return {
    ...shard,
    sessions,
  };
}

function removeSession(state: AppState, event: SessionClearEvent): AppState {
  return updateShard(state, event.driverId, (old) =>
    updateSessionInShardStatus(old, event.symbol, () => null)
  );
}

function updateSession(state: AppState, event: SessionUpdateEvent): AppState {
  return updateShard(state, event.driverId, (old) =>
    updateSessionInShardStatus(old, event.status.symbol, () => event.status)
  );
}

function toSessions(raw: SessionStatus[]): Sessions {
  let sessions: Sessions = {};
  raw.forEach((s) => (sessions[s.symbol] = s));
  return sessions;
}

function updatePrices(
  state: AppState,
  priceUpdates: BinancePriceUpdate[]
): AppState {
  let prices = { ...state.prices };
  priceUpdates.forEach((p) => (prices[p.s] = parseFloat(p.c)));
  return {
    ...state,
    lastPriceUpdate: Date.now(),
    prices,
  };
}

export function reducer(state: AppState, action: Action): AppState {
  switch (action.type) {
    case "SummaryUpdate":
      return {
        ...state,
        mainAccountBalance: action.data.mainAccountBalance,
      };
    case "SessionClear":
      let newState = removeSession(state, action);
      return updateShard(newState, action.driverId, (s) => ({
        ...s,
        balance: action.balance,
      }));
    case "SessionUpdate":
      return updateSession(state, action);
    case "ShardRestore":
      return updateShard(state, action.driverId, (old) => ({
        ...old,
        sessions: toSessions(action.sessions),
      }));
    case "LoadShards":
      let map: Map<string, ShardStatus> = Map();
      action.data.forEach(
        (d) => (map = map.set(printDriverID(d.driverId), d.data))
      );

      return {
        ...state,
        shards: map,
      };
    case "PriceUpdate":
      return updatePrices(state, action.data);
    case "GasUpdate":
      return { ...state, ethGas: action.gas };
    case "EmaUpdate":
      return {...state, isEmaPositive: action.data};
  }
  console.error("failed to run reducer: ", action);
  return state;
}
