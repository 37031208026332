import { useState } from "react";

export function AuthKeyInput(props: { onConfirm: (val: string) => void }) {
  const [value, setValue] = useState("");
  return (
    <input
      className="AuthKeyInput"
      type="password"
      value={value}
      onChange={(o) => setValue(o.target.value)}
      onKeyDown={(o) =>
        o.key === "Enter" && value.length > 0 && props.onConfirm(value)
      }
      placeholder="ENTER AUTH KEY HERE AND PRESS ENTER"
    />
  );
}
