import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Dashboard from "./Dashboard";
import { SnackbarProvider } from "notistack";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ControlCenter from "./ControlCenter";
import { createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
  palette: { mode: "dark" },
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/cc" element={<ControlCenter />} />
          </Routes>
        </SnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
