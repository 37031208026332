import axios from "axios";
import { Shards, ShowSnackMsg, Summary } from "./model";
import { Dispatcher } from "./reducer";

function buildGetURL(action: string, key: string): string {
  return `https://btrade.txie.studio/api/btrade/${action}?api_key=${key}`;
}

export async function refreshShards(
  authKey: string,
  dispatch: Dispatcher,
  showMsg: ShowSnackMsg
) {
  let resp = await axios.get(buildGetURL("get_all", authKey));
  let shards: Shards = resp.data;
  dispatch({ type: "LoadShards", data: shards });
  showMsg("shards refreshed.");
}

export async function getSummary(authKey: string, dispatch: Dispatcher) {
  const resp = await axios.get(buildGetURL("summary", authKey));
  let summary: Summary = resp.data;
  dispatch({ type: "SummaryUpdate", data: summary });
}

export async function getEmaStatus(authKey: string, dispatch: Dispatcher) {
  const resp = await axios.get(buildGetURL("ema_positve", authKey));
  dispatch({ type: "EmaUpdate", data: resp.data });
}
