import "./Dashboard.css";

import { memo, useEffect, useReducer, useState } from "react";
import { PricesMap, SessionStatus, ShardStatus } from "./model";
import { reducer } from "./reducer";
import { pad } from "./utils";
import { AuthKeyInput } from "./AuthKeyInput";
import { init_binance_price_socket, init_socket } from "./ws";
import { useSnackbar } from "notistack";
import { Map } from "immutable";
import { getEmaStatus } from "./query";

function shortSymbol(symbol: string): string {
  return symbol.substring(0, symbol.length - 4);
}

function getProgressStyleString(progress: number, isPositive: boolean): string {
  const backgroundColor = "#2c3344";
  const positiveColor = "#1ba7f5";
  const negativeColor = "#a156ff";
  const progressPercent = progress * 100;
  if (isPositive) {
    return `conic-gradient(from -90deg, ${positiveColor} ${
      progressPercent / 2
    }%, 0, ${backgroundColor})`;
  } else {
    return `conic-gradient(from -90deg, ${backgroundColor} ${
      100 - progressPercent / 2
    }%, 0, ${negativeColor})`;
  }
}

function getProgressStyle(session: SessionStatus, price?: number) {
  let progress = 0;
  let isPositive = false;
  if (!price) {
    return "#33394b";
  }
  if (session.direction == "Long") {
    let midPoint =
      (session.profitPrice - session.nextLegPrice) / 2 + session.nextLegPrice;
    if (price >= midPoint) {
      progress = (price - midPoint) / (session.profitPrice - midPoint);
      isPositive = true;
    } else {
      progress = (midPoint - price) / (midPoint - session.nextLegPrice);
      isPositive = false;
    }
  } else {
    let midPoint =
      (session.nextLegPrice - session.profitPrice) / 2 + session.profitPrice;
    if (price <= midPoint) {
      progress = (midPoint - price) / (midPoint - session.profitPrice);
      isPositive = true;
    } else {
      progress = (price - midPoint) / (session.nextLegPrice - midPoint);
      isPositive = false;
    }
  }

  return getProgressStyleString(progress, isPositive);
}

function Session(props: { session: SessionStatus; price?: number }) {
  const { session } = props;

  return (
    <div
      className={`Session ${session.direction === "Long" ? "Long" : "Short"}`}
    >
      <div
        className="Progress"
        style={{ background: getProgressStyle(session, props.price) }}
      />
      <div className="Mask" />
      <div className="Inner">
        <div>{shortSymbol(session.symbol)}</div>
        <div className="Splitter" />
        <div className="LegBlock">{session.leg}</div>
      </div>
    </div>
  );
}

const PureSession = memo(Session);

function ShardIndex(props: { driverID: string }) {
  const idx = parseInt(props.driverID.split("_")[1]);
  const prefix = props.driverID.split("_")[0][0];
  return (
    <div className="ShardIndex">
      <div>
        <b>{prefix}</b>
      </div>
      <div>{pad(idx, 2)}</div>
    </div>
  );
}

function ShardSummaryItem(props: { label: string; value: string | number }) {
  return (
    <div className="ShardSummaryItem">
      <span>{props.label}</span>:<span>{props.value}</span>
    </div>
  );
}

function Shard(props: {
  driverID: string;
  shard: ShardStatus;
  prices: PricesMap;
}) {
  if (Object.keys(props.shard.sessions).length === 0) {
    return null;
  }
  let keys = Object.keys(props.shard.sessions).sort();
  return (
    <div className="Shard">
      <ShardIndex driverID={props.driverID} />
      <div className="Expand">
        <div className="ShardSummary">
          <ShardSummaryItem
            label="balance"
            value={props.shard.balance.walletBalance.toFixed(0)}
          />
        </div>
        <div className="SessionContainer">
          {keys.map((key) => (
            <PureSession
              key={key}
              session={props.shard.sessions[key]}
              price={props.prices[key]}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

function HeaderSplitter() {
  return <div className="HeaderSplitter" />;
}

function StatusIndicator(props: { label?: string; isOn: boolean | null }) {
  return (
    <div className={`StatusIndicator ${props.isOn == null? "Null" : props.isOn ? "On" : "Off"}`}>
      <div className="Dot" />
      {props.label && <div className="Label">{props.label}</div>}
    </div>
  );
}

function Header(props: {
  lastPriceUpdate: number;
  ethGas: number;
  balance: number;
  mainAccountBalance: number;
  emaStatus: boolean | null;
}) {
  return (
    <div className="Header">
      <StatusIndicator isOn={props.emaStatus} />
      <HeaderSplitter />
      <div className="StatusIndicator">{`${props.ethGas} gwei`}</div>
      <HeaderSplitter />
      <div className="StatusIndicator">{`balance: $${props.balance.toFixed(
        0
      )} / $${props.mainAccountBalance.toFixed(0)}`}</div>
    </div>
  );
}

const PureHeader = memo(Header);

function Dashboard() {
  const [authKey, setAuthKey] = useState<string | null>(
    localStorage.getItem("auth_key")
  );
  const { enqueueSnackbar } = useSnackbar();
  const emptyMap: Map<string, ShardStatus> = Map();
  const [state, dispatch] = useReducer(reducer, {
    prices: {},
    shards: emptyMap,
    lastPriceUpdate: 0,
    ethGas: 0,
    mainAccountBalance: 0,
    isEmaPositive: null,
  });
  useEffect(() => {
    if (!authKey) {
      return;
    }
    const init = async () => {
      try {
        getEmaStatus(authKey, dispatch);
        init_socket(authKey, dispatch, enqueueSnackbar);
        init_binance_price_socket(dispatch, enqueueSnackbar);
        localStorage.setItem("auth_key", authKey);
      } catch (e) {
        console.error("failed to init", e);
        setAuthKey(null);
      }
    };

    init().catch((e) => console.error("error during init", e));
  }, [authKey]);

  return (
    <div className="Root">
      <PureHeader
        ethGas={state.ethGas}
        lastPriceUpdate={state.lastPriceUpdate}
        balance={state.shards
          .map((s) => s.balance.walletBalance)
          .reduce((partial, a) => partial + a, 0)}
        mainAccountBalance={state.mainAccountBalance}
        emaStatus={state.isEmaPositive}
      />
      {!authKey && <AuthKeyInput onConfirm={setAuthKey} />}
      {authKey && (
        <div className="ShardContainer">
          {state.shards
            .toArray()
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map((shard) => (
              <Shard
                key={shard[0]}
                driverID={shard[0]}
                shard={shard[1]}
                prices={state.prices}
              />
            ))}
        </div>
      )}
    </div>
  );
}

export default Dashboard;
